import { useParams } from "react-router-dom";

import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import SingleTours from "../components/singleTours/singleTours";

function SingleToursPage() {
    const { tour_name } = useParams()

    return (
        <>
            <Header />

            <main className="main">
                <SingleTours title={tour_name} />
            </main>

            <Footer />
        </>
    )
}

export default SingleToursPage