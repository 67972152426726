import './toursIntro.scss'
import { Link } from 'react-router-dom'

import Arrow from '../../assets/image/arrow.svg'

function ToursIntro() {
    return (
        <section className="tours-intro">
            <div className="container">
                <div className='tours-intro__model'>
                    <div className='tours-intro__box'>
                        <h1 className='tours-intro__heading'>Tours</h1>
                        <p className='tours-intro__path'>
                            <Link className='tours-intro__link' to={'/'}>Home</Link>
                            <img className='tours-intro__arrow' src={Arrow} alt="arrow icon" width={7} height={9} />
                            <span>Tours</span>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ToursIntro