import { useEffect, useState } from 'react'
import './homeIntro.scss'

import Samarkand from '../../assets/image/registan.jpg'
import Samarkand2 from '../../assets/image/place.jpg'
import Khiva from '../../assets/image/khiva.jpg'
import Bukhara from '../../assets/image/bukhara.jpg'

const data = [
    {
        id: 1,
        city: 'Samarkand',
        image: Samarkand
    },
    {
        id: 2,
        city: "Khiva",
        image: Khiva
    },
    {
        id: 3,
        city: "Bukhara",
        image: Bukhara
    },
    {
        id: 4,
        city: "Samarkand",
        image: Samarkand2
    },
    {
        id: 5,
        city: "Kyrgyzstan",
        image: "https://i0.wp.com/voyage-avicenne.uz/wp-content/uploads/2023/02/img-02-4.jpg?resize=600%2C409&ssl=1",
    },
]

function HomeIntro() {
    const [places, setPlaces] = useState(data)

    const [index, setIndex] = useState(0);


    useEffect(() => {
        const lastIndex = places.length - 1;

        if (index > lastIndex) {
            setIndex(0);
        }

        if (index < 0) {
            setIndex(lastIndex);
        }
    }, [index, places]);

    useEffect(() => {
        let slider = setInterval(() => {
            setIndex(index + 1);
        }, 4995);
        return () => clearInterval(slider);
    });

    const next = () => {
        const lastIndex = places.length - 1;
        if (index == lastIndex) {
            setIndex(0);
        } else {
            setIndex(index + 1);
        }
    };
    const back = () => {
        const lastIndex = places.length - 1;

        if (index == 0) {
            setIndex(lastIndex);
        } else {
            setIndex(index - 1);
        };
    }

    return (
        <>
            <section className='home-intro' id='homeintro'>
                <div className="container">
                    <div className='home-intro__box'>
                        <div className='home-intro__left'>
                            <p className='home-intro__title'>Amazing Places</p>
                            <h1 className='home-intro__heading'>Make Your Trip Fun & Noted</h1>
                            <p className='home-intro__desc'>
                                Travel has helped us to understand the meaning of life and it has helped us become better people. Each time we travel, we see the world with new eyes.
                            </p>
                        </div>

                        <div className='home-intro__right'>
                            <img className='home-intro__right__image' src={places[index]?.image} alt="Amazing Places" width={800} height={800} />

                            <div className='home-intro__right__btn-box'>
                                <button className='home-intro__right__btn-prev' onClick={back}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg>
                                </button>
                                <button className='home-intro__right__btn-next' onClick={next}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg>
                                </button>
                            </div>

                            <div className='home-intro__right__info-box'>
                                <p className='home-intro__right__info__text'>{places[index]?.city}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeIntro

