import './homeAbout.scss';
import { useNavigate } from 'react-router-dom';

function HomeAbout() {
	const navigate = useNavigate();

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	return (
		<>
			<section className='home-about'>
				<div className='container reveal'>
					<div className='home-about__box'>
						<div className='home-about__left'>
							{/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={200}
                                height={150}
                                viewBox="0 0 512 512">
                                <path
                                    d="M256 73.82A182.18 182.18 0 1 0 438.18 256 182.182 182.182 0 0 0 256 73.82zm67.825 192.217L218.7 326.734a10.376 10.376 0 0 1-15.566-8.99V196.356a10.38 10.38 0 0 1 15.575-8.99l105.125 60.696a10.376 10.376 0 0 1-.009 17.974z"
                                    data-name="Play" />
                            </svg> */}
						</div>

						<div className='home-about__right'>
							<h2 className='home-about__right__heading'>About Us</h2>
							<p className='home-about__right__text'>
								Mayintepa Voyage is a travel agency based in Samarkand,
								in the heart of Uzbekistan and the Silk Road. With solid
								experience in the field, the Avicenne Travel Agency and
								its entire team bring you their know-how in organizing
								trips to Uzbekistan and Central Asia.
							</p>

							<ul className='home-about__right__list'>
								<li className='home-about__right__item'></li>
							</ul>

							<div className='home-about__right__btn-box'>
								<button
									className='home-about__right__btn'
									onClick={() => navigate('/contact')}>
									Contact Us
								</button>
								<button
									className='home-about__right__btn'
									onClick={() => {
										navigate('/about')
										.then(res => {
											window.location.reload()
										})
									}}>
									Read More
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HomeAbout;
