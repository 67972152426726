import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Trip from "../components/trip/trip"
import { useParams } from "react-router-dom";

function TripPage() {
    

    return (
        <>
            <Header />
            <main className="main">
                <Trip />
            </main>

            <Footer />
        </>
    )
}

export default TripPage