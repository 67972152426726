import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import ToursIntro from "../components/toursIntro/toursIntro"
import ToursList from "../components/toursList/toursList"

function ToursPage() {
    return (
        <>
            <Header />

            <main className="main">
                <ToursIntro />
                <ToursList />
            </main>

            <Footer />

        </>
    )
}

export default ToursPage