import { useEffect, useState } from 'react';
import './weather.scss';

function Weather() {
	const [currency, setCurrency] = useState([]);
	const [tashkent, setTashkent] = useState({});
	const [samarqand, setSamarqand] = useState({});
	const [bukhara, setBukhara] = useState({});

	useEffect(() => {
		fetch('https://cbu.uz/uz/arkhiv-kursov-valyut/json/')
			.then((res) => res.json())
			.then((data) => setCurrency(data.slice(0, 3)));
	}, []);

	useEffect(() => {
		fetch(
			'https://api.openweathermap.org/data/2.5/weather?q=toshkent&appid=36880fe33e6b197773b6f2c90946a066&units=metric',
		)
			.then((res) => res.json())
			.then((data) => setTashkent(data));
	}, []);

	useEffect(() => {
		fetch(
			'https://api.openweathermap.org/data/2.5/weather?q=samarkand&appid=36880fe33e6b197773b6f2c90946a066&units=metric',
		)
			.then((res) => res.json())
			.then((data) => setSamarqand(data));
	}, []);

	useEffect(() => {
		fetch(
			'https://api.openweathermap.org/data/2.5/weather?q=bukhara&appid=36880fe33e6b197773b6f2c90946a066&units=metric',
		)
			.then((res) => res.json())
			.then((data) => setBukhara(data));
	}, []);

	return (
		<section className='weather'>
			<div className='container'>
				<ul className='currency__list'>
					<li className='currency__item'>
						<div>
							<p>USD = {currency[0]?.Rate}</p>
							<span>{currency[0]?.Diff}</span>
						</div>
						<img
							src='https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg'
							alt='america flag'
							width={50}
							height={50}
						/>
					</li>
					<li className='currency__item'>
						<div>
							<p>EUR = {currency[1]?.Rate}</p>
							<span>{currency[1]?.Diff}</span>
						</div>
						<img
							src='https://www.nationsonline.org/gallery/Flags/Flag-of-European-Union.jpg'
							alt='europe flag'
							width={50}
							height={50}
						/>
					</li>
					<li className='currency__item'>
						<div>
							<p>RUB = {currency[2]?.Rate}</p>
							<span>{currency[2]?.Diff}</span>
						</div>
						<img
							src='https://upload.wikimedia.org/wikipedia/en/thumb/f/f3/Flag_of_Russia.svg/800px-Flag_of_Russia.svg.png'
							alt='russia flag'
							width={50}
							height={50}
						/>
					</li>
				</ul>

				<ul className='weather__list'>
					<li>
						{tashkent.main && (
							<div className='city'>
								<h2 className='city-name'>
									<span>{tashkent.name}</span>
									<sup>{tashkent.sys.country}</sup>
								</h2>
								<div className='city-temp'>
									{Math.round(tashkent.main.temp)}
									<sup>&deg;C</sup>
								</div>
								<div className='info'>
									<img
										className='city-icon'
										src={`https://openweathermap.org/img/wn/${tashkent.weather[0].icon}@2x.png`}
										alt={tashkent.weather[0].desccription}></img>
									<p>{tashkent.weather[0].description}</p>
								</div>
							</div>
						)}
					</li>
					<li>
						{samarqand.main && (
							<div className='city'>
								<h2 className='city-name'>
									<span>{samarqand.name}</span>
									<sup>{samarqand.sys.country}</sup>
								</h2>
								<div className='city-temp'>
									{Math.round(samarqand.main.temp)}
									<sup>&deg;C</sup>
								</div>
								<div className='info'>
									<img
										className='city-icon'
										src={`https://openweathermap.org/img/wn/${samarqand.weather[0].icon}@2x.png`}
										alt={samarqand.weather[0].desccription}></img>
									<p>{samarqand.weather[0].description}</p>
								</div>
							</div>
						)}
					</li>
					<li>
						{bukhara.main && (
							<div className='city'>
								<h2 className='city-name'>
									<span>{bukhara.name}</span>
									<sup>{bukhara.sys.country}</sup>
								</h2>
								<div className='city-temp'>
									{Math.round(bukhara.main.temp)}
									<sup>&deg;C</sup>
								</div>
								<div className='info'>
									<img
										className='city-icon'
										src={`https://openweathermap.org/img/wn/${bukhara.weather[0].icon}@2x.png`}
										alt={bukhara.weather[0].desccription}></img>
									<p>{bukhara.weather[0].description}</p>
								</div>
							</div>
						)}
					</li>
				</ul>
			</div>
		</section>
	);
}

export default Weather;
