import './formFeedback.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { uid } from 'uid';
import { onValue, ref, set } from 'firebase/database';

function FormFeedback() {
	const [data, setData] = useState([]);
	const [name, setName] = useState('');
	const [comment, setComment] = useState('');

	useEffect(() => {
		onValue(ref(db), (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				Object.values(data).map((feedback) => {
					setData((oldData) => [...oldData, feedback]);
				});
			}
		});
	}, []);

	const addFeedback = (e) => {
		e.preventDefault();
		// const { name, comment } = e.target.elements;
		const uuid = uid();
		set(ref(db, `/${uuid}`), {
			name,
			comment,
		});

		setName('');
		setComment('');
	};

	return (
		<>
			<section className='feedback-form'>
				<div className='container'>
					<h2 className='feedback-form__heading'>Send Comment</h2>
					<form onSubmit={addFeedback}>
						<input
							className='feedback-form__input'
							type='text'
							name='name'
							placeholder='Your Name'
							required
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<textarea
							className='feedback-form__input'
							name='comment'
							placeholder='Your comment'
							required
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>
						<button className='feedback-form__btn'>Send Comment</button>
					</form>
				</div>
			</section>

			<section className='feedback'>
				<div className='container'>
					<Swiper
						navigation={true}
						modules={[Navigation, Autoplay]}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
						}}
						className='mySwiper'>
						{data.map((e, i) => {
							return (
								<SwiperSlide key={i}>
									<div className='feedback__box'>
										<h4 className='feedback__title'>{e.name}</h4>
										<p className='feedback__text'>{e.comment}</p>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</div>
			</section>
		</>
	);
}

export default FormFeedback;
