import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import HomeIntro from '../components/homeIntro/homeIntro';
import HomeAbout from '../components/homeAbout/homeAbout';
import HomeTours from '../components/homeTours/homeTours';
import HomeInfo from '../components/homeInfo/homeInfo';
import HomeTeam from '../components/homeTeam/homeTeam';
import Weather from '../components/weather/weather';
import FormFeedback from '../components/formFeedback/formFeedback';
import Contact from '../components/contact/contact';

function HomePage() {
	return (
		<>
			<Header />

			<main className='main'>
				<HomeIntro />
				<HomeAbout />
				<HomeTours />
				<HomeInfo />
				<HomeTeam />
				<Weather />
				<FormFeedback />
				<Contact />
			</main>

			<Footer />
		</>
	);
}

export default HomePage;
