import { useState } from "react";
import "./trip.scss";
import { useEffect } from "react";
import data from "./data";
import { useParams } from "react-router-dom";

function Trip() {
  const { id } = useParams();

  const [trip, setTrip] = useState({});

  useEffect(() => {
    if (id) {
      setTrip(data?.find((e) => e?.en?.title?.toLowerCase() === id?.toLowerCase()));
    }
  }, [id]);

  console.log(trip);

  return (
    <section className="trip">
      <div className="container">
        <div className="trip__box">
          <div className="trip__heading-box">
            <h2 className="trip__heading">{trip?.en?.title}</h2>
            <p className="trip__text">{trip?.en?.text}</p>
          </div>
          <img className="trip__image" src={trip?.image} alt={trip?.en?.title} />
        </div>
        {trip?.table_image ? (
          <img
            className="trip__table"
            src={trip?.table_image}
            alt={trip?.en?.title}
          />
        ) : (
          ""
        )}
        {trip?.en?.days?.map((e) => (
          <>
            <h3 className="trip__title">{e.title}</h3>
            <p className="trip__text">{e.text}</p>
          </>
        ))}
      </div>
    </section>
  );
}

export default Trip;
