import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import AboutIntro from "../components/aboutIntro/aboutIntro"
import About from "../components/about/about"
import HomeTeam from "../components/homeTeam/homeTeam"


function AboutPage() {
    return (
        <>
            <Header />

            <main className="main">
                <AboutIntro />
                <About />
                <HomeTeam />
            </main>

            <Footer />

        </>
    )
}

export default AboutPage