import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import ContactIntro from "../components/contactIntro/contactIntro"
import SendMessage from "../components/sendMessage/sendMessage"
import Contact from "../components/contact/contact"

function ContactPage() {
    return (
        <>
            <Header />

            <main className="main">
                <ContactIntro />
                <SendMessage />
                <Contact />
            </main>

            <Footer />

        </>
    )
}

export default ContactPage