import { useParams } from "react-router-dom";

import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import SingleInfo from "../components/singleInfo/singleInfo";

function SingleInfoPage() {
    const { info_title } = useParams()

    return (
        <>
            <Header />

            <main className="main">
                <SingleInfo title={info_title} />
            </main>

            <Footer />
        </>
    )
}

export default SingleInfoPage