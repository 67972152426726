import { useState } from 'react';
import './homeInfo.scss';
import { useNavigate } from 'react-router-dom';

import img1 from "../../assets/image/rypcvpbstc-1.jpg";
import img2 from "../../assets/image/emx8lkew4aavgaa-1.jpg";
import img3 from "../../assets/image/1670468955_41-kartinkin-net-p-kartinka-tadzhikistan-pinterest-43.jpg";

const data = [
	{
		id: 1,
		image: img1,
		title: 'The must-sees of Uzbekistan',
		desc: 'In the footsteps of Marco Polo and the conquerors of Central Asia: the Persians, Alexander the Great, the Arabs, Genghis Khan...discover those that were long considered the three most beautiful cities in the world: Samarkand, Bukhara, Khiva. ..',
	},
	{
		id: 2,
		image: img2,
		title: 'Uzbekistan crossroads of civilizations',
		desc: 'Uzbekistan, whose territory corresponds to the crossroads of the Silk Road, will offer you an exceptional discovery with its mythical and legendary cities such as Samarkand, Bukhara, Khiva, each with more than 2500 years of history.',
	},
	{
		id: 3,
		image: img3,
		title: 'In the footsteps of Marco Polo',
		desc: 'In the footsteps of Marco Polo and the conquerors of Central Asia: the Persians, Alexander the Great, the Arabs, Genghis Khan...discover those that were long considered the three most beautiful cities in the world: Samarkand, Bukhara, Khiva.',
	},
];

function HomeInfo() {
	const [info, setInfo] = useState(data);
	const navigate = useNavigate();

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	return (
		<section className='home-info'>
			<div className='container reveal'>
				<h2 className='home-info__heading'>Popular information</h2>

				<ul className='home-info__list'>
					{info.map((e, i) => (
						<li className='home-info__item' key={i}>
							<img
								src={e.image}
								alt='image of info'
								width={400}
								height={270}
							/>

							<div className='home-info__item__box'>
								<h3 className='home-info__item__title'>{e.title}</h3>
								<p className='home-info__item__desc'>{e.desc}</p>
							</div>
						</li>
					))}
				</ul>

				<button
					className='home-info__btn'
					onClick={() => navigate('/information')}>
					See All
				</button>
			</div>
		</section>
	);
}

export default HomeInfo;
