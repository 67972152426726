import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import HomePage from './page/home';
import ToursPage from './page/tours';
import InformationPage from './page/information';
import AboutPage from './page/about';
import ContactPage from './page/contact';
import SingleToursPage from './page/singleTour';
import SingleInfoPage from './page/singleInfo';
import TripPage from './page/trip';

function App() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Routes>
			<Route path='/' element={<HomePage />} />
			<Route path='/tours' element={<ToursPage />} />
			<Route path='/tour/:tour_name' element={<SingleToursPage />} />
			<Route path='/information' element={<InformationPage />} />
			<Route path='/information/:info_title' element={<SingleInfoPage />} />
			<Route path='/trip/:id' element={<TripPage />} />
			<Route path='/about' element={<AboutPage />} />
			<Route path='/contact' element={<ContactPage />} />
		</Routes>
	);
}

export default App;
