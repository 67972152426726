import { useState } from "react";
import "./homeTeam.scss";

import img1 from "../../assets/image/hasanjon.jpg";
import img2 from "../../assets/image/zukhra.jpg";
import img3 from "../../assets/image/nasimjon.jpg";
import img4 from "../../assets/image/janonbek.jpg";

const data = [
  {
    id: 1,
    name: "Janonbek Sanakulov",
    image: img4,
    position:
      "Fondateur et directeur de « Mayintepa Voyage », guide conférencier francophone",
  },
  {
    id: 5,
    name: "Nasimjon Sanakulov",
    image: img3,
    position:
      "Guide accompagnateur et conférencier francophone, connaît parfaitement le pays et partagera ses connaissances avec son humour",
  },
  {
    id: 3,
    name: "Jahongir Sanakulov",
    image:
      "https://voyage-avicenne.uz/wp-content/uploads/2023/03/photo_2023-03-15_09-16-15.jpg",
    position:
      "Guide accompagnateur francophone, responsable du marché francophone, très dynamique",
  },
  {
    id: 2,
    name: "Hasanjon Sanakulov",
    image: img1,
    position:
      "Guide accompagnateur francophone, guide expérimenté, sourire garantis",
  },
  {
    id: 4,
    name: "Zukhra Jalilova",
    image: img2,
    position: "Guide locale francophone, relation clientèle",
  },
];

function HomeTeam() {
  const [members, setMembers] = useState(data);

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  return (
    <section className="home-team">
      <div className="container reveal">
        <h2 className="home-team__heading">Our Team & Guide</h2>
        <p className="home-team__desc">
          Our small structure assures you a service and a personalized
          follow-up, in relation to your needs, as well in the organization of
          circuits as trips or à la carte stays. Our rich experience as tour
          guides gives us a perfect knowledge of the clientele and their
          expectations and offers you the guarantee of quality services adapted
          to your needs. Our goal is to make you discover Uzbekistan "in the
          heart" with passion and professionalism in this spirit of welcome
          specific to our culture and which has enchanted travelers since the
          dawn of time!
        </p>

        <ul className="home-team__list">
          {members.map((e, i) => (
            <li className="home-team__item" key={i}>
              <img src={e.image} alt={e.name} width={270} height={300} />
              <h3 className="home-team__item__title">{e.name}</h3>
              <p className="home-team__item__text">{e.position}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default HomeTeam;
