import { useState } from 'react';
import './toursList.scss';
import { useNavigate } from 'react-router-dom';
import img11 from '../../assets/image/img-04-3.webp';
import img12 from '../../assets/image/tadzhikistana.webp';
import img13 from '../../assets/image/img-02-4.webp';
import img14 from '../../assets/image/img-03-4-scaled.webp';
import img15 from '../../assets/image/photo_2023-03-08_21-36-12.webp';

const data = [
	{
		id: 1,
		title: 'Uzbekistan',
		image: img11,
		desc: 'Uzbekistan prospered at the crossroads of major caravan routes from China, Siberia, Persia and the West, until the surge of the hordes of Genghis',
	},
	{
		id: 2,
		title: 'Tajikistan',
		image: img12,
		desc: 'Tajikistan prospered at the crossroads of major caravan routes from China, Siberia, Persia and the West, until the surge of the hordes of Genghis',
	},
	{
		id: 3,
		title: 'Kyrgyzstan',
		image: img13,
		desc: 'Kyrgyzstan prospered at the crossroads of major caravan routes from China, Siberia, Persia and the West, until the surge of the hordes of Genghis',
	},
	{
		id: 4,
		title: 'Turkmeniston',
		image: img14,
		desc: 'Turkmeniston prospered at the crossroads of major caravan routes from China, Siberia, Persia and the West, until the surge of the hordes of Genghis',
	},
	{
		id: 5,
		title: 'Kazakhstan',
		image: img15,
		desc: 'Kazakistan prospered at the crossroads of major caravan routes from China, Siberia, Persia and the West, until the surge of the hordes of Genghis',
	},
];

function ToursList() {
	const [tours, Settours] = useState(data);
	const navigate = useNavigate();

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	return (
		<section className='tours-list'>
			<div className='container reveal'>
				<h2 className='tours-list__heading'>Our tours</h2>

				<ul className='tours-list__list'>
					{tours.map((e, i) => (
						<li
							className='tours-list__item'
							key={i}
							onClick={() => navigate(`/tour/${e.title}`)}>
							<img
								src={e.image}
								alt={e.title}
								width={400}
								height={280}
							/>

							<div className='tours-list__item__box'>
								<h3 className='tours-list__item__title'>{e.title}</h3>
								<p className='tours-list__item__desc'>{e.desc}</p>
								<button
									className='tours-list__item__btn'
									onClick={() => navigate(`/tour/${e.title}`)}>
									More
								</button>
							</div>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
}

export default ToursList;
