import './header.scss';
import { Link, NavLink } from 'react-router-dom';

import Logo from '../../assets/image/logo.png';
import Instagram from '../../assets/image/instagram.svg';
import Telegram from '../../assets/image/telegram.svg';
import Facebook from '../../assets/image/facebook.svg';
import Youtube from '../../assets/image/youtube.svg';
import Translate from '../translate-element';
import { useEffect } from 'react';

function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
  }

function Header() {
	

	return (
		<header className='header'>
			<Translate />
			<div className='container'>
				<div className='header__box'>
					<div className='header__logo-box'>
						<a href={'/#homeintro'}>
							<img
								className='logo'
								src={Logo}
								alt='logo'
								width={150}
								height={100}
							/>
						</a>
					</div>

					<nav className='header__nav desktop'>
						<ul className='header__nav__list'>
							<li className='header__nav__item'>
								<NavLink className='header__nav__link' to={'/'}>
									Welcome
								</NavLink>
							</li>
							<li className='header__nav__item'>
								<NavLink className='header__nav__link' to={'/tours'}>
									Tours
								</NavLink>
							</li>
							<li className='header__nav__item'>
								<NavLink
									className='header__nav__link'
									to={'/information'}>
									Information
								</NavLink>
							</li>
							<li className='header__nav__item'>
								<NavLink className='header__nav__link' to={'/about'}>
									About us
								</NavLink>
							</li>
							<li className='header__nav__item'>
								<NavLink className='header__nav__link' to={'/contact'}>
									Contact us
								</NavLink>
							</li>
							<li className='header__nav__item'>
							<select className='header__select' value={getCookie("googtrans") ? getCookie("googtrans")?.split("/en/")[1] : "en"} onChange={(e) => {
									document.cookie = "googtrans" + "=" + ""  + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
									document.cookie = "googtrans" + "=" + (e.target.value == "en" ? ""  : `/en/${e.target.value}`)  + "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
									window.location.reload()
								}} >
									<option value='en'>English</option>
									<option value='ru'>Russian</option>
									<option value='uz'>Uzbek</option>
									<option value='fr'>France</option>
									<option value='de'>German</option>
									<option value="it">Italian</option>
								</select>
							</li>
						</ul>
					</nav>

					<ul className='header__social-list'>
						<li>
							<a
								className='header__item__box__link insta'
								href='/'
								target='_blank'>
								<img
									src={Instagram}
									alt='Instagram icon'
									width={30}
									height={30}
								/>
							</a>
						</li>

						<li>
							<a
								className='header__item__box__link telegram'
								href='/'
								target='_blank'>
								<img
									src={Telegram}
									alt='Telegram icon'
									width={30}
									height={30}
								/>
							</a>
						</li>

						<li>
							<a
								className='header__item__box__link facebook'
								href=''
								target='_blank'>
								<img
									src={Facebook}
									alt='Facebook icon'
									width={30}
									height={30}
								/>
							</a>
						</li>

						<li>
							<a
								className='header__item__box__link youtube'
								href='/'
								target='_blank'>
								<img
									src={Youtube}
									alt='Youtube icon'
									width={30}
									height={30}
								/>
							</a>
						</li>
					</ul>

					<input className='checkbox' type='checkbox' />
					<div className='hamburger-lines'>
						<span className='line line1'></span>
						<span className='line line2'></span>
						<span className='line line3'></span>
					</div>

					<ul className='header__nav__list mobile-menu'>
						<li className='header__nav__item'>
							<NavLink className='header__nav__link' to={'/'}>
								Welcome
							</NavLink>
						</li>
						<li className='header__nav__item'>
							<NavLink className='header__nav__link' to={'/tours'}>
								Tours
							</NavLink>
						</li>
						<li className='header__nav__item'>
							<NavLink className='header__nav__link' to={'/information'}>
								Information
							</NavLink>
						</li>
						<li className='header__nav__item'>
							<NavLink className='header__nav__link' to={'/about'}>
								About us
							</NavLink>
						</li>
						<li className='header__nav__item'>
							<NavLink className='header__nav__link' to={'/contact'}>
								Contact us
							</NavLink>
						</li>

						<li className='header__nav__item'>
							<ul className='header__social-list--mobile'>
								<li>
									<a
										className='header__item__box__link insta'
										href='/'
										target='_blank'>
										<img
											src={Instagram}
											alt='Instagram icon'
											width={30}
											height={30}
										/>
									</a>
								</li>

								<li>
									<a
										className='header__item__box__link telegram'
										href='/'
										target='_blank'>
										<img
											src={Telegram}
											alt='Telegram icon'
											width={30}
											height={30}
										/>
									</a>
								</li>

								<li>
									<a
										className='header__item__box__link facebook'
										href=''
										target='_blank'>
										<img
											src={Facebook}
											alt='Facebook icon'
											width={30}
											height={30}
										/>
									</a>
								</li>

								<li>
									<a
										className='header__item__box__link youtube'
										href='/'
										target='_blank'>
										<img
											src={Youtube}
											alt='Youtube icon'
											width={30}
											height={30}
										/>
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</header>
	);
}

export default Header;
