import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import InfoIntro from "../components/infoIntro/infoIntro"
import InfoList from "../components/infoList/infoList"

function InformationPage() {
    return (
        <>
            <Header />

            <main className="main">
                <InfoIntro />
                <InfoList />
            </main>

            <Footer />

        </>
    )
}

export default InformationPage